import axios from "axios";
import { getParams } from '@/utils/global-functions';

const BALANCE_RESOURCE = 'balances';
export class BlogHttpRepository  {

    // https://cdn.contentful.com
    async getPosts(query?: any): Promise<any> {
        let params = ''
        if (query)
            params = '&'+getParams(query)
        return (await axios.get('https://cdn.contentful.com/spaces/ba5i755vttyo/entries?access_token=ZS2qxPCUB38SCiJZfBqX_TaiM-OSGBzwjzcH8yk2Uhg&content_type=bithonorBlogPost&select=fields.category,fields.title,fields.description,fields.image,sys.createdAt,sys.id'+params)).data
    }

    async getPostById(id: string): Promise<any> {
        return (await axios.get(`https://cdn.contentful.com/spaces/ba5i755vttyo/entries?access_token=ZS2qxPCUB38SCiJZfBqX_TaiM-OSGBzwjzcH8yk2Uhg&select=fields.category,fields.title,fields.description,fields.image,sys.createdAt,sys.id,fields.content&sys.id[match]=${id}&content_type=bithonorBlogPost`)).data
    }

    async getCategories(query?: any): Promise<any> {
        let params = ''
        if (query)
            params = '&'+getParams(query)
        return (await axios.get('https://cdn.contentful.com/spaces/ba5i755vttyo/entries?access_token=ZS2qxPCUB38SCiJZfBqX_TaiM-OSGBzwjzcH8yk2Uhg&content_type=bithonorCategories'+params)).data
    }
}

export const blogHttpRepository = new BlogHttpRepository();