
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
/* COMPONENTS */
import Header from '@/modules/landingPage/components/layout/Header.vue';
import Footer from '@/modules/landingPage/components/layout/Footer.vue';
import { isoDateToDDMMYYYY } from '@/utils/date-functions'
/* INTERFACES */
/* STORE */

@Component({
  components: {
      Header,
      Footer
  },
})
export default class BlogPostCard extends Vue {

    @Prop() id!: string
    @Prop() title!: string
    @Prop() description!: string
    @Prop() image!: string
    @Prop() date!: string
    @Prop() categories!: Array<string>

    async mounted(){
       
    }

    get formattedDate(){
        return isoDateToDDMMYYYY(this.date)
    }

    sendToPost(){
        this.$router.push('/blog/' + this.id)
    }


}
